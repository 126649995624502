/* RESPONSIBLE TEAM: team-purchase */

import { modifier } from 'ember-modifier';

type MessengerAction =
  | 'startTour'
  | 'showArticle'
  | 'showNews'
  | 'startSurvey'
  | 'startChecklist'
  | 'showTicket';

export interface AttachMessengerActionSignature {
  Element: HTMLElement;
  Args: {
    Positional: [
      MessengerAction,
      string, // ID of the article, survey, or ticket etc. e.g. "1234"
      string?, // an optional selector to find the element(s) to attach the click listener to
    ];
  };
}

const AttachMessengerAction = modifier<AttachMessengerActionSignature>(
  (targetElement, [action, id, selector]) => {
    function onClickListener(): void {
      window.Intercom(action, id);
    }

    // if selector is defined, get all elements that match the selector, otherwise use the target element
    let elements: Array<HTMLElement> = selector
      ? Array.from(targetElement.querySelectorAll(selector))
      : [targetElement];

    // attach the on click listener to all elements
    elements.forEach((element) => {
      element.addEventListener('click', onClickListener);
    });

    // remove the on click listener from all elements on destruction
    return () => {
      elements.forEach((element) => {
        element.removeEventListener('click', onClickListener);
      });
    };
  },
  { eager: false },
);

export default AttachMessengerAction;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'attach-messenger-action': typeof AttachMessengerAction;
  }
}
