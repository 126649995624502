/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier } from 'ember-modifier';

const onIntersection = modifier(
  (
    element: HTMLElement,
    [onIntersectionFn, onNotIntersectionFn]: [Function] | [Function, Function],
    { options }: { options?: { root: HTMLElement; rootMargin: string } },
  ) => {
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          onNotIntersectionFn?.();
          return;
        }

        onIntersectionFn();
      });
    }, options);

    observer.observe(element);

    return function cleanupObserver() {
      if (observer) {
        observer.unobserve(element);
        observer.disconnect();
      }
    };
  },
);

export default onIntersection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'on-intersection': typeof onIntersection;
  }
}
