/* RESPONSIBLE TEAM: team-proactive-support */
import { modifier } from 'ember-modifier';

function detectHorizontalScrolls(element) {
  function checkScrollPosition() {
    let scrolledAllTheWayRight = element.scrollWidth - element.scrollLeft - element.clientWidth < 1;
    let scrolledAllTheWayLeft = element.scrollLeft === 0;

    if (scrolledAllTheWayRight) {
      element.classList.remove('o__scrolled-right');
    } else {
      element.classList.add('o__scrolled-right');
    }

    if (scrolledAllTheWayLeft) {
      element.classList.remove('o__scrolled-left');
    } else {
      element.classList.add('o__scrolled-left');
    }
  }

  element.addEventListener('scroll', checkScrollPosition);
  window.addEventListener('resize', checkScrollPosition);
  let mutationObserver = new MutationObserver(() => {
    if (element) {
      checkScrollPosition();
    }
  });
  mutationObserver.observe(element, { childList: true, subtree: true });

  checkScrollPosition();

  return () => {
    element?.removeEventListener('scroll', checkScrollPosition);
    window.removeEventListener('resize', checkScrollPosition);
    mutationObserver?.disconnect();
  };
}

export default modifier(detectHorizontalScrolls);
