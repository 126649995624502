/* RESPONSIBLE TEAM: team-frontend-tech */
/* eslint-disable promise/prefer-await-to-then */
import Modifier from 'ember-modifier';

import ENV from 'embercom/config/environment';

const CONTAINER_ID = 'react-component-container';

let reactRefreshPreambleLoaded = false;

const ensureReactRefreshPreambleLoaded = async () => {
  if (reactRefreshPreambleLoaded) {
    return;
  }

  reactRefreshPreambleLoaded = true;

  let reactRefreshPreamble = new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = 'http://embercom.test:4200/a/react-refresh.js';
    script.type = 'module';
    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });

  let viteClient = new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = 'http://localhost:5173/r/@vite/client';
    script.type = 'module';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });

  await Promise.all([reactRefreshPreamble, viteClient]);
};

const createReactScript = () => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = ENV.APP.teammateAppIslandEntryUrl;
    script.type = 'module';
    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const createStyleLink = (shadowRoot: ShadowRoot) => {
  return new Promise((resolve, reject) => {
    let styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    styleLink.href = ENV.APP.teammateAppIslandStylesUrl;
    styleLink.onload = resolve;
    styleLink.onerror = reject;
    shadowRoot.appendChild(styleLink);
  });
};

const createShadowRoot = async (element: Element) => {
  let shadowRoot = element.attachShadow({ mode: 'open' });

  await createStyleLink(shadowRoot);

  return shadowRoot;
};

const renderReactComponent = async (element: Element, props: any) => {
  if (ENV.environment === 'test') {
    return;
  }

  if (ENV.environment === 'development') {
    await ensureReactRefreshPreambleLoaded();
  }

  await createReactScript();

  if (!window.ReactTeammateApp) {
    throw new Error('ReactTeammateApp not found after loading script');
  }

  let shadowRoot = await createShadowRoot(element);
  let container = document.createElement('div');
  container.style.position = 'relative';
  container.style.zIndex = '9999';
  container.id = CONTAINER_ID;

  shadowRoot.appendChild(container);

  window.ReactTeammateApp.experimental.renderPaywall(container, props);
};

interface Args {
  Positional: [{ open: boolean; planId: string; onOpenChange: (open: boolean) => void }];
}

interface RenderReactComponentSignature {
  Element: Element;
  Args: Args;
}

class RenderReactComponent extends Modifier<RenderReactComponentSignature> {
  ignore = false;

  modify(element: Element, [props]: Args['Positional']) {
    if (this.ignore) {
      return;
    }
    this.ignore = true;

    renderReactComponent(element, props)
      .then(() => {
        this.ignore = false;
      })
      .catch((error) => {
        this.ignore = false;
        console.error(error);
      });
  }
}

export default RenderReactComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'render-react-component': typeof RenderReactComponent;
  }
}
