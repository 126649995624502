/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import type ActionHeader from '../action-header';
import type TestResult from './test-result';
import { isPresent } from '@ember/utils';
import type MockResponse from './mock-response';
import type ResponseField from './response-field';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  url: [
    validator('presence', {
      presence: true,
      messageKey: 'workflow-connector.builder.body.request.url.https',
    }),
    validator('format', { type: 'url' }),
  ],
});

export default class ApiRequestConfig extends Fragment.extend(Validations) {
  @attr('string') declare url: string;
  @attr('string') declare httpMethod: string;
  @attr('string') declare body: string | null;
  @attr('string') declare tokenId: string | null;
  @attr('string') declare configurationResponseType: string;
  @fragmentArray('workflow-connector/action-header') declare headers: ActionHeader[];
  @fragment('workflow-connector/customer-data-verification/test-result')
  declare testResult: TestResult;
  @fragment('workflow-connector/customer-data-verification/mock-response')
  declare mockResponse: MockResponse;
  @fragmentArray('workflow-connector/customer-data-verification/response-field')
  declare responseFields: ResponseField[];

  get attributes() {
    let parameterRegex = new RegExp(/{{(.*?)}}/g);
    let text = this.url || '';
    if (['post', 'put', 'patch'].includes(this.httpMethod)) {
      text += this.body;
    }
    return text.matchAll(parameterRegex);
  }

  get isRequestConfigurationValid() {
    return !this.installUrlErrors;
  }

  get installUrlErrors() {
    return this.validations.errors.filter(
      (error: { attribute: string }) => error.attribute === 'url',
    )[0];
  }

  get hasValidResponse() {
    return (this.isTestResponse && this.testIsValid) || (this.isMockResponse && this.mockIsValid);
  }

  get isTestResponse() {
    return this.configurationResponseType === 'test_response_type';
  }

  get isMockResponse() {
    return this.configurationResponseType === 'mock_response_type';
  }

  get testIsValid() {
    if (isPresent(this.testResult?.actionResponse)) {
      if (this.isJsonInvalid) {
        return false;
      } else if (!this.hasDirtyAttributes) {
        return true;
      } else if (isPresent(this.testResult.testedAction)) {
        return this.isTestActionValid;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get isJsonInvalid() {
    if (!this.testResult?.actionResponse?.rawBody) {
      return false;
    }

    try {
      JSON.parse(this.testResult?.actionResponse?.rawBody);
    } catch (e) {
      return true;
    }
    return false;
  }

  get mockIsValid() {
    try {
      JSON.parse(this.mockResponse?.body);
    } catch (e) {
      return false;
    }
    return true;
  }

  get isTestActionValid() {
    return (
      this.httpMethod === this.testResult.testedAction.httpMethod &&
      this.url === this.testResult.testedAction.url &&
      this.headers === this.testResult.testedAction.headers &&
      this.body === this.testResult.testedAction.body &&
      this.tokenId === this.testResult.testedAction.tokenId
    );
  }
}
