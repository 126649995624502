/* RESPONSIBLE TEAM: team-actions */
import Model, { attr } from '@ember-data/model';
import Admin from 'embercom/models/admin';

export default class ActionExecutionResult extends Model {
  @attr('boolean') declare success: boolean;
  @attr('number') declare conversationId: number;
  @attr('number') declare userId: number;
  @attr('number') declare adminId: number;
  @attr('string') declare httpMethod: string;
  @attr('string') declare sourceType: string;
  @attr('number') declare sourceId: number;
  @attr('string') declare sourceUrl: string;
  @attr('string') declare httpStatus: string;
  @attr('string') declare errorType: string;
  @attr('string') declare errorMessage: string;
  @attr('string') declare requestUrl: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('boolean') declare securityRisksAccepted: boolean;
  @attr('boolean') declare idvProtected: boolean;
  @attr('boolean') declare otpProtected: boolean;
  @attr() declare requestBody: any;
  @attr() declare requestHeaders: any;
  @attr() declare responseBody: any;
  @attr() declare rawResponseBody: any;

  get admin() {
    return Admin.peekAndMaybeLoad(this.store, this.adminId?.toString());
  }
}
