/* RESPONSIBLE TEAM: team-purchase */
import { modifier } from 'ember-modifier';

// fixes an issue where video will not autoplay if page is navigated to directly. (used for intershop add on page)
export default modifier(async (element) => {
  // required for failing silently during tests. this will fail due to Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause().
  try {
    // 'muted' must be set to true otherwise browser will throw "Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first."
    element.muted = true;
    await element.play();
  } catch (e) {
    // do nothing
  }
});
