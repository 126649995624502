/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier as buildModifier } from 'ember-modifier';

function copyWidthFromConversationDetailsSidebar(
  element: HTMLElement,
  [sidebarElement]: [HTMLElement],
) {
  if (!sidebarElement) {
    return;
  }

  function copyWidth() {
    element.style.width = `${sidebarElement.clientWidth}px`;
  }

  let observer = new ResizeObserver(copyWidth);
  observer.observe(sidebarElement);

  copyWidth();

  return function cleanupObserver() {
    if (observer) {
      observer.unobserve(sidebarElement);
      observer.disconnect();
    }
  };
}

let modifier = buildModifier(copyWidthFromConversationDetailsSidebar, { eager: false });

export default modifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'copy-width-from-conversation-details-sidebar': typeof modifier;
  }
}
